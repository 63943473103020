import React from "react"
import styled from "styled-components"
import { Link } from 'gatsby'
import OrganonLogo from '../../assets/organon-logo.svg';
import AccessibilityIcon from '../../assets/accessibility-icon.png';

const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
        <FooterCopy>
          This site is intended only for residents of the United States.
        </FooterCopy>
        <FooterLogoContainer>
          <OrganonLogo/>
          <FooterNavigation>
            <Link to='https://organon.com/terms-of-use/'>Terms of Use</Link>
            <Link to='https://organon.com/privacy/'>Privacy Policy</Link>
            <a href='javascript:void(0)' className="ot-sdk-show-settings">Cookie Preferences</a>
          </FooterNavigation>
        </FooterLogoContainer>
        <FooterCopy>
          © 2021 Organon group of companies. All rights reserved. US-NON-110001 04/21
        </FooterCopy>
      </FooterContainer>

      <FooterTruste>
        <FooterTrusteLink>
          <a href="https://www.essentialaccessibility.com/organon" target="_blank" rel="noreferrer" title="">
            <img src={AccessibilityIcon} alt="" />
          </a>
        </FooterTrusteLink>
        <FooterTrusteLink>
          <a href="https://privacy.truste.com/privacy-seal/validation?rid=1ee67517-0029-4dd0-86b0-1262f41e225e" target="_blank" title="TRUSTe APEC Privacy">
            <img src="//privacy-policy.truste.com/privacy-seal/seal?rid=1ee67517-0029-4dd0-86b0-1262f41e225e" alt="TRUSTe APEC Privacy" />
          </a>
        </FooterTrusteLink>
      </FooterTruste>
  </FooterWrapper>
)

const FooterWrapper = styled.div`
  width: 100%;
  color: var(--black);
  background: var(--light-gray);
  padding: 0.6rem 1.0875rem;
  font-family: 'Montserrat';
  display: flex;
  justify-content: space-between;

  @media (max-width: 415px) {
    flex-direction: column;
  }
`
const FooterContainer = styled.div`
  position: relative;
`

const FooterCopy = styled.div`
  font-size: 10px;
  padding-left: 1.3rem;

  @media (max-width: 415px) {
    font-size: 8px;
  }
`

const FooterLogoContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 115px;
    height: auto;
    margin-right: 20px;
  }

  @media (max-width: 415px) {
    svg {
      width: 95px;
      margin-right: 5px;
    }
  }
`
const FooterNavigation = styled.div`
  display: inline;
  position: relative;
  color: var(--dark-blue);
  font-size: 10px;
  font-weight: bold;

  @media (max-width: 415px) {
    font-size: 8px;
  }

  a {
    padding-right: 15px;
  }

  a:after {
    content: '';
    background: var(--dark-blue);
    width: 2px;
    height: 10px;
    position: absolute;
    top: 8px;
    margin-left: 6px;
  }

  a:last-child:after{
    content: '';
    background: none;
  }
`

const FooterTruste = styled.div`
  display: flex;
  align-items: center;

  // Comments out until second TRUSTe logo is added
  // a:first-child {
  //   margin-right: 20px;
  // }

  img {
    margin-bottom: 0;
  }

  @media (max-width: 415px) {
    align-self: center;
  }
`

const FooterTrusteLink = styled.div`
  @media (max-width: 415px) {
    margin-top: 10px;
  }

  &:first-child {
    margin-right: 2rem;
  }
`

export default Footer
