import React from "react"
import styled from "styled-components"
import background from "../../assets/con-desktop-hero-banner.jpg";
import MobileBackground from "../../assets/con-mobile-hero-banner.jpg";
import Icon1 from "../../assets/con-desktop-hero-insurance.png";
import Icon2 from "../../assets/con-desktop-hero-copay.png";
import Icon3 from "../../assets/con-desktop-hero-referral.png";
import MobileIcon1 from "../../assets/con-mobile-hero-insurance.png";
import MobileIcon2 from "../../assets/con-mobile-hero-copay.png";
import MobileIcon3 from "../../assets/con-mobile-hero-referral.png";
import WhiteArrow from '../../assets/white-arrow.png';

const HeroContainer = styled.div`
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: contain;
    height: auto;
    width: calc(100% + 30px);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    transform: translateX(-15px);
    -webkit-transform: translateX(-15px);

    @media (max-width: 415px) {
        background: none;
        height: 100%;
    }
`;

const MobileHero = styled.div`
    display: none;

    @media (max-width: 415px) {
        display: block;
        background-image: url(${MobileBackground});
        background-repeat: no-repeat;
        background-size: contain;
        width: calc(100% + 30px);
        transform: translateX(-15px);
        -webkit-transform: translateX(-15px);
        height: 162px;
    }
`

const ComponentWrapper = styled.div`
    padding: 18px 0 0 14px;

    @media (max-width: 775px) {
        padding: 6px 0 0 14px;
    }
`;

const TagLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: flex-start;
    padding-left: 6px;

    @media (max-width: 415px) {
        padding-left: 0;
    }

    h1 {
        color: var(--white);
        font-weight: 600;
        margin-bottom: 0.9rem;
        font-size: 1.3em;

        span {
            font-weight: 300;
        }

        @media (max-width: 415px) {
            color: var(--black);
            font-size: 16px;
            margin-bottom: 0;
        }

        @media (max-width: 775px) {
            margin-bottom: 0.3em;
        }
    }
`;

const QuestionContainer = styled.div`
    padding-left: 6rem;

    @media (max-width: 415px) {
        padding-left: 60px;
        padding-right: 15px;
    }

    @media (max-width: 775px) {
        padding-left: 5rem;
    }
`;

const QuestionItemOne = styled.div`
    display: flex;
    align-items: center;
    height: 52px;
    color: var(--white);
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 6px;
    position: relative;

    @media (max-width: 775px) {
        height: 41px;
    }

    p:before {
        content: '';
        background-image: url(${Icon1});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 47px;
        position: absolute;
        top: 4px;
        left: -4.5rem;

        @media (max-width: 415px) {
            background-image: url(${MobileIcon1});
            left: -3.5rem;
        }

        @media (max-width: 775px) {
            height: 37px;
        }
    }
`;

const QuestionItemTwo = styled.div`
    display: flex;
    align-items: center;
    height: 54px;
    color: var(--white);
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 9px;
    position: relative;

    @media (max-width: 775px) {
        height: 39px;
    }

    p:before {
        content: '';
        background-image: url(${Icon2});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 47px;
        position: absolute;
        top: 6px;
        left: -4.1rem;

        @media (max-width: 415px) {
            background-image: url(${MobileIcon2});
            left: -3.4rem;
        }

        @media (max-width: 775px) {
            height: 36px;
            top: 4px;
        }
    }
`;

const QuestionItemThree = styled.div`
    display: flex;
    align-items: center;
    height: 67px;
    color: var(--white);
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 9px;
    position: relative;

    @media (max-width: 775px) {
        height: 51px;
    }

    p:before {
        content: '';
        background-image: url(${Icon3});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 38px;
        position: absolute;
        top: 15px;
        left: -4.6rem;

        @media (max-width: 415px) {
            background-image: url(${MobileIcon3});
            height: 34px;
            left: -3.8rem;
        }

        @media (max-width: 775px) {
            height: 35px;
            top: 7px;
        }
    }
`;

const Detail = styled.p`
    margin-bottom: 0;
    padding-left: 8px;
    font-weight: 500;

    @media (max-width: 415px) {
        color: var(--black);
        font-size: 12px;
        line-height: 14px;
        br {
            display: none;
        }
    }

    @media (max-width: 775px) {
        font-size: 13px;
        line-height: 15px;
    }
`;

const HeroCTAText = styled.div`
    color: var(--white);
    padding-left: 6px;
    margin-top: 4px;

    @media (max-width: 415px) {
        color: var(--black);
        max-width: 60%;
        padding-left: 0;
        margin-top: 0;
    }

    @media (max-width: 775px) {
        margin-top: 0;
    }

    > h1 {
        color: inherit;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 1.1rem;

        @media (max-width: 415px) {
            font-size: 12px;
        }

        @media (max-width: 775px) {
            margin-bottom: 0;
        }
    }

    > p {
        font-size: 14px;
        line-height: 1.4;
        color: var(--white);

        @media (max-width: 415px) {
            font-size: 12px;
            line-height: 14px;
            color: var(--black);

            br {
                display: none;
            }
        }

        @media (max-width: 775px) {
            line-height: 1.2;
        }
    }
`;

const CTAContainer = styled.div`
    display: flex;
`;

const Button = styled.div`
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: var(--white);
    background: var(--pink);
    height: 100%;
    font-weight: bold;
    padding: 7px 50px 7px 12px;
    border-radius: 4px;
    margin: 20px 35px 0px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
    position: relative;

    @media (max-width: 320px) {
        font-size: 9px !important;
    }

    @media (max-width: 415px) {
        margin: 20px 8px 0px !important;
        font-size: 12px;
        padding: 0 30px 0 8px;
    }

    @media (max-width: 775px) {
        margin: 10px 35px 0px;
    }

    a {
        color: var(--white);
        &:after {
            content: '';
            background-image: url(${WhiteArrow});
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 24px;
            position: absolute;
            top: 8px;
            left: 87%;
            z-index: 1;

            @media (max-width: 415px) {
                height: 15px;
                left: 88%;
                top: 4px;
            }
        }
    }

    :after {
        content: '';
        background-color: var(--light-pink);
        position: absolute;
        width: 40px;
        margin: -7px 10px 4px 14px;
        height: 40px;
        border-radius: 4px;

        @media (max-width: 415px) {
            height: 23px;
            width: 24px;
            margin: 0px 0px 4px 8px;
        }
    }
`;

const HccPageHero = () => (
    <>
    <MobileHero/>
    <HeroContainer>
        <ComponentWrapper>
            <TagLine>
                <h1>
                    The Organon Access Program
                    <br/> <span>may be able to help answer questions about:</span>
                </h1>
            </TagLine>
            <QuestionContainer>
                <QuestionItemOne>
                    <Detail>Insurance coverage</Detail>
                </QuestionItemOne>
                <QuestionItemTwo>
                    <Detail>Co-pay assistance for eligible patients</Detail>
                </QuestionItemTwo>
                <QuestionItemThree>
                    <Detail>Referral to the Organon Patient Assistance Program
                    <br/> for eligibility determination (provided through the 
                    <br/> Organon Patient Assistance Program Inc.)
                    </Detail>
                </QuestionItemThree>
            </QuestionContainer>
            <CTAContainer>
                <HeroCTAText> {/*  textblock for getstarted */}
                    <h1>Get Started</h1>
                    <p>
                        To learn more about this program, please click on the
                        <br/> “Learn More” button or the logo of your medication below.
                    </p>
                </HeroCTAText>
                <Button><a href="/hcc/organon-access-program/">LEARN MORE</a></Button>
            </CTAContainer>
        </ComponentWrapper>
    </HeroContainer>
    </>
);

export default HccPageHero;