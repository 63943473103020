import React from "react"
import Layout from "../../components/layout"
import { Container } from "../../components/layout/layoutComponents"
import SEO from "../../components/seo"
import HccPageHero from "../../components/HccPageHero"
import HccCardRow from '../../components/HccCardRow'
import SecondaryCardRow from '../../components/SecondaryCardRow'
import HccHeader from "../../components/HccHeader"
import HccFooter from "../../components/HccFooter"
import { ComponentWrapper } from "../../components/layout/ComponentWrapper"

const IndexPage = () => (
  <>
    <HccHeader />
    <ComponentWrapper>
      <Layout>
        <Container>
          <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
          <HccPageHero />
          <HccCardRow/>
          <SecondaryCardRow/>
        </Container>
      </Layout>
    </ComponentWrapper>
    <HccFooter/>
  </>
)

export default IndexPage