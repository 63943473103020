import React from "react"
import styled from "styled-components"
import OrganonLogo from '../../assets/organon-logo.svg'
import { Link } from 'gatsby'

const ComponentWrapper = styled.div`
  padding: 0 28px;
  margin-bottom: 5px;

  @media (max-width: 320px) {
    padding: 0 20px;
  }
`;

const PageNavigationStyle = styled.div`
    display: flex;
    font-family: 'Montserrat';
    font-size: 14px;
    a {
      padding-left: 3px;
      padding-right: 3px;

      :last-child {
        background-color: var(--ice-blue);
      }
    }
    > * {
        margin: 5px 7px 5px 0;
    }

    @media (max-width: 768px) {
      justify-content: center;
      font-size: 0.6em;
  }

    @media (max-width: 415px) {
      align-items: center;
      flex-direction: row;
    }
`
;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  color: var(--dark-blue);
  @media (max-width: 415px) {
    height: 45px;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 0px;
  > span {
    font-weight: lighter;
  }
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
  @media (max-width: 415px) {
    font-size: 1rem;
  }
`;

const Logo = styled.div`
  display: flex;
  svg {
    width: 235px;
    height: auto;
    @media (max-width: 415px) {
      width: 115px;
    }
  }
`;

const Header = ({ siteTitle, className }) => (
  <ComponentWrapper>
    <PageNavigationStyle>
      <Link to='/hcp/'>Health Care Professionals</Link>
      <span>|</span>
      <Link to='/hcc/'>Patients and Caregivers</Link>
    </PageNavigationStyle>
    <HeaderWrapper>
      <HeaderTitle>
        <span>The Organon</span>
          <br/>
          <nobr>Access Program</nobr>
      </HeaderTitle>
      <Logo>
        <OrganonLogo />
      </Logo>
    </HeaderWrapper>
  </ComponentWrapper>
)

export default Header
